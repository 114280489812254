import { get, put,Delete,post } from '../axios'

// 商品分类-新增
export const goodsCategoryAdd = params => post(`/goods/web/goodsCategory/add`,params);
// 商品分类-列表
export const goodsCategoryList = params => get(`/goods/web/goodsCategory/all/list`,params);
// 商品分类-删除
export const goodsCategoryDel = params => Delete(`/goods/web/goodsCategory/delete/${params}`);
// 商品分类-是否显示
export const goodsCategoryState = params => put(`/goods/web/goodsCategory/state`,params);
// 商品分类-编辑
export const goodsCategoryUpdate = params => put(`/goods/web/goodsCategory/update`,params);

// 商品-新增
export const goodsAdd = params => post(`/goods/web/goods/add`,params);
// 商品-商品审核
export const goodsCheck = params => put(`/goods/web/goods/platform/check`,params);
// 商品-删除
export const goodsDel = params => Delete(`/goods/web/goods/delete/${params}`);
// 商品-分页列表
export const goodsPageList = params => get(`/goods/web/goods/platform/pageList`,params);

// 商品-上下架-批量上下架
export const goodsShelve = params => put(`/goods/web/goods/platform/shelve`,params);
// 商品-编辑
export const goodsUpdate = params => put(`/goods/web/goods/update`,params);
// 商品-查看
export const goodsView = params => get(`/goods/web/goods/view/${params}`);

// 平台-设置为首页品牌商品
export const goodsSet = params => put(`/goods/web/goods/store/set`,params);


//商品品牌 -新增
export const goodBrandAdd = params => post(`/goods/web/goodBrand/add`,params);
//商品品牌 -删除
export const goodBrandDel = params => Delete(`/goods/web/goodBrand/delete/${params}`);
//商品品牌 -分页列表
export const goodBrandPageList = params => get(`/goods/web/goodBrand/pageList`,params);
//商品品牌 -是否显示
export const goodBrandState = params => put(`/goods/web/goodBrand/state`,params);
//商品品牌 -编辑 
export const goodBrandUpdate = params => put(`/goods/web/goodBrand/update`,params);
//商品品牌 -编辑
export const goodBrandListAll = params => get(`/goods/web/goodBrand/list`,params);

//商品分组 -新增
export const goodsClassifyAdd = params => post(`/goods/web/goodsClassify/add`,params);
//商品分组 -删除
export const goodsClassifyDel = params => Delete(`/goods/web/goodsClassify/delete/${params}`);
//商品分组 -分页列表
export const goodsClassifyList = params => get(`/goods/web/goodsClassify/all/list`,params);
//商品分组 -是否显示
export const goodsClassifyState = params => put(`/goods/web/goodsClassify/state`,params);
//商品分组 -编辑
export const goodsClassifyUpdate = params => put(`/goods/web/goodsClassify/update`,params);

// 门店商品-分页列表
export const goodsStorePageList = params => get(`/goods/web/goods/store/pageList`,params);
// 平台-门店商品列表(banner跳转选择)
export const platformStorePageList= params => get(`/goods/web/goods/platform/store/pageList`,params);

// 平台商品-商品池
export const goodsPlatformPool = params => get(`/goods/web/goods/platform/pool/pageList`,params);

// 平台商品-平台分组列表
export const goodsClassifyAllList = params => get(`/goods/web/goodsClassify/all/list`,params);

// 门店端-部门树
export const goodsDeptTree = params => get(`/user/web/store/deptTree`,params);

// 门店商品-新增
export const goodsStoreAdd = params => post(`/goods/web/goods/store/add`,params);

// 门店商品-新增
export const goodsStoreUp = params => put(`/goods/web/goods/store/update`,params);
// 门店商品-批量上下架-推荐商品
export const storeShelve = params => put(`/goods/web/goods/store/shelve`,params);

// 门店商品-移除-批量移除
export const storeDel = params => put(`/goods/web/goods/store/delete`,params);

// 门店商品-移除-批量移除
export const storeView = params => get(`/goods/web/goods/store/view/${params.id}?type=${params.type}`);

// 平台售后列表
export const refundAllPageList = params => get(`/order/web/refund/all/pageList`,params);

// 审核售后
export const refundAudit = params => post(`/order/web/refund/audit`,params);
//p1-平台-供应商-门店-下拉选择列表
export const goodBrandTotalListAll = params => get(`/goods/web/goodBrand/total/list/${params}`);

//新增
export const sourceMaterialAdd = params => post(`/goods/core/sourceMaterial/add`,params);
//删除
export const sourceMaterialADel = params => Delete(`/goods/core/sourceMaterial/delete/${params}`);
// 导出
export const getSourceMaterialExportExcel = params => get(`/goods/core/sourceMaterial/exportExcel`,params);
// 分页列表
export const getSourceMaterialPageList = params => get(`/goods/core/sourceMaterial/pageList`,params);
// 编辑
export const sourceMaterialUpdate= params => put(`/goods/core/sourceMaterial/update`,params);
// 门店-平台商品池导出
export const storePoolExport = params => put(`/goods/web/goods/store/pool/export`,params);
// 门店-门店-商品批量导入
export const goodsImport = params => put(`/goods/web/goods/store/goods/import`,params);

// p1-拖拽排序
export const goodsClassifySort = params => put(`/goods/web/goodsClassify/sort`,params);

// 商品单位-列表分页
export const getGoodsUnitList = params => get(`/goods/web/goodsUnit/list`,params);
// 商品单位-新增
export const goodsUnitAdd = params => post(`/goods/web/goodsUnit/add`,params);
// 商品单位-编辑
export const goodsUnitUp = params => put(`/goods/web/goodsUnit/update`,params);
// 商品单位-删除
export const goodsUnitDel = params => Delete(`/goods/web/goodsUnit/delete/${params}`);
// 商品单位-下拉列表
export const getGoodsUnitListAll = params => get(`/goods/web/goodsUnit/listAll`,params);
// 商品设置-一键同步
export const goodsWeimobSync = params => get(`/goods/weimob/sync`,params);

// // 活动商品新增
// export const activityGoodsAdd = params => post(`/goods/web/activity/goods/add`,params);
// // 活动商品修改
// export const activityGoodsUp = params => put(`/goods/web/activity/goods/update`,params);